.waveform-sec-ctn .form-control {
    width: 100px;
    font-size: 22px;
}

.waveform-date input {
    width: 180px;
}

.waveformAlert {
    margin-top: 30px;
    white-space: "pre-wrap";
}

.plot-loading-bars-waveform-analysis-page {
    width: 100%;
    min-height: 770px;
    position: absolute;
    z-index: 2;
    padding-left: 50%;
    padding-top: 330px;
}

@supports(backdrop-filter: blur(5px)) {
    .plot-loading-bars-waveform-analysis-page {
        backdrop-filter: blur(10px);
    }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .plot-loading-bars-waveform-analysis-page {
        background-color: rgba(255, 255, 255, .8);
        filter: blur(3px);
    }
}