
.voltageLabel{
    font-size: 15px;
    padding-top: 8px !important;
    padding-left: 5.7% !important;
}

.l-lDropdown{
    font-size: 14px;
}

.timezoneDropdown{
    font-size: 14px;
}


@media only screen and (max-width: 1500px){

    .voltageLabel{
        padding-left: 5% !important;
    }
}



