.plot-loading-bars-real-time-metering-page{
    width: 100%;
    min-height: 770px;
    position: absolute;
    z-index: 2;
    padding-left: 50%;
    padding-top: 330px;
}


@supports(backdrop-filter: blur(5px)){
    .plot-loading-bars-real-time-metering-page{
        backdrop-filter: blur(10px);
    }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .plot-loading-bars-real-time-metering-page{
        background-color: rgba(255, 255, 255, .8);
        filter: blur(3px);
    }
  }