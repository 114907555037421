  .table-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: auto;
    min-width: 800px;
  }

  .fixed-table td,
  .fixed-table th {
    font-size: 12px;
  }

  .fixed-table td {
    word-wrap: break-word;
  }

  .DailyReportTable_tableCSS table,
  .DailyReportTable2 table,
  .DailyReportTable3 table,
  .DailyReportTable4 table,
  .DailyReportTable5 table,
  .DailyReportTable6 table {
    font-size: 12px;
  }

  .DailyReportTable_tableCSS th,
  .DailyReportTable2 th,
  .DailyReportTable3 th,
  .DailyReportTable4 th,
  .DailyReportTable5 th,
  .DailyReportTable6 th {
    position: sticky !important;
    top: -2px;
    font-size: 14px;
    height: 5rem;
  }

  .DailyReportTable2 {
    /* height: 450px; */
    padding-bottom: 0px;
    padding-top: 0px;
    margin-top: 20px;
  }

  .DailyReportTable3 {
    /* height: 450px; */
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .DailyReportTable4 {
    /* height: 450px; */
  }

  .DailyReportTable5 {
    /* height:450px; */
    /*margin-top: 50px;*/
  }

  /* .select-dropdown{
  overflow-y: visible !important;
} */

  .Select-option:nth-child(n+3) {
    display: none;
  }

  .Select-option:last-child,
  .Select-option:nth-child(2) {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .tableDescription {
    font-size: medium;
    margin: 0.5rem 0;
    white-space: pre-wrap;
  }