.bodyText {
	white-space: pre-line;
	text-align: left;
}

.alertsTable {
	font-size: 14px;
}

.first-col-width th:nth-child(1){
	width: 20%;
}

.last-col-width th:nth-child(3){
	width: 20%;
}

.alertsBody {
	text-align: left;
}

.btn_alert {
	border-radius: 20px !important;
	font-size: 18px !important;
}

.refreshBtn {
	background-image: url('./refreshWhite.ico');
	background-size: contain;
	background-color: transparent;
    white-space: nowrap;
	border: none;
	width: 20px;
	height: 20px;
}

.refreshBtn:active {
	transform: rotate(-0.25turn);
}

