.plot-loading-bars-One-Second-NewTrending-page{
    width: 100%;
    min-height: 970px;
    position: absolute;
    z-index: 2;
    padding-left: 50%;
    padding-top: 330px;
  }
  
  @supports(backdrop-filter: blur(5px)){
    .plot-loading-bars-One-Second-NewTrending-page{
        backdrop-filter: blur(10px);
      }
  }
  
  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .plot-loading-bars-One-Second-NewTrending-page{
        background-color: rgba(255, 255, 255, .8);
          filter: blur(3px);
      }
    }