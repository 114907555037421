.plot-loading-bars{
  width: 100%;
  min-height: 780px;
  position: absolute;
  z-index: 2;
  padding-left: 50%;
  padding-top: 330px;

}

.plot-loading-bars-events-page-for-None{
  width: 100%;
  min-height: 780px;
  position: absolute;
  z-index: 2;
  padding-left: 50%;
  padding-top: 330px;
}
.plot-loading-bars-events-page-for-selected-analysis{
  width: 100%;
  min-height: 980px;
  position: absolute;
  z-index: 2;
  padding-left: 50%;
  padding-top: 330px;
}


@supports(backdrop-filter: blur(5px)){
  .plot-loading-bars-events-page-for-selected-analysis, .plot-loading-bars-events-page-for-None, .plot-loading-bars{
    backdrop-filter: blur(10px);
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .plot-loading-bars-events-page-for-selected-analysis, .plot-loading-bars-events-page-for-None, .plot-loading-bars{
    background-color: rgba(255, 255, 255, .8);
    filter: blur(3px);
  }
}